<template>
  <div :class="wrapperClassSetting" :style="{ width: full ? '100%' : '50%' }">
    <div class="vx-col sm:w-1/3 w-full flex" style="min-width: 100px">
      <label v-if="showTitle" for="teritory">Teritory</label>
    </div>
    <div :class="multiselectWrapperClass">
      <multiselect
        id="teritory"
        v-model="selectedTerritory"
        :options="
          multiSelect
            ? this.$store.state.operatingUnit.filter.territory
            : this.$store.state.operatingUnit.filter.territory_single
        "
        :multiple="multiSelect"
        :allow-empty="true"
        :group-select="false"
        :max-height="120"
        :limit="3"
        placeholder="Select teritory"
        track-by="TerritoryID"
        label="TerritoryName"
        :custom-label="queryTerritory"
        :disabled="disabled"
      >
        <template slot="option" slot-scope="props">
          <div class="option__desc">
            <span class="option__title">
              <span class="option__title"
                >({{ props.option.TerritoryCode }}),
                {{ props.option.TerritoryName }}</span
              >
            </span>
          </div>
        </template>

        <template slot="tag" slot-scope="props">
          <div class="multiselect__tag">
            <span>{{ props.option.TerritoryName }}</span>
            <i
              class="multiselect__tag-icon"
              @click.prevent
              @mousedown.prevent.stop="props.remove(props.option, $event)"
            />
          </div>
        </template>
      </multiselect>
    </div>
    <!-- <div class="w-full flex justify-end">
        <vs-button @click="onShow">Show</vs-button>
      </div> -->
  </div>
</template>
<script>
import Multiselect from "vue-multiselect";
// import "vue-multiselect/dist/vue-multiselect.min.css";
export default {
  props: {
    full: {
      type: Boolean,
      default: false,
    },
    multiSelect: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    showTitle: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    multiselect: Multiselect,
  },
  data() {
    return {
      selectedOperatingUnit: [],
      // selectedTerritory: Object,
      // selectedTerritorySingle: {},
      wrapperClassSetting: "",
      multiselectWrapperClass: "",
      wrapperWidth: 0,
      labelClass: "",
    };
  },
  mounted() {
    if (this.multiSelect) {
      const selectedTerritory =
        this.$store.state.operatingUnit.filter.selectedTerritories;
      if (selectedTerritory.length === 0) {
        this.$store.commit(
          "operatingUnit/setSelectedTerritories",
          this.$store.state.operatingUnit.filter.territory[0]
        );
      }
    } else {
      const selectedTerritory =
        this.$store.state.operatingUnit.filter.selectedTerritory;
      if (Object.keys(selectedTerritory).length === 0) {
        this.$store.commit(
          "operatingUnit/setSelectedTerritory",
          this.$store.state.operatingUnit.filter.territory[0]
        );
      }
    }
    console.log("selectedTerritory", this.selectedTerritory);
  },
  created() {
    // this.$store.watch(
    //   (state) => state.operatingUnit.filter.selectedOperatingUnits,
    //   () => {
    //     this.$store.commit("operatingUnit/setTerritoryFilterByOperatingUnits");
    //   }
    // );

    if (this.full) {
      this.wrapperClassSetting = "flex flex-col w-full";
      this.multiselectWrapperClass = "vx-col w-full whitespace-no-wrap";
      this.labelClass = "mb-2";
    } else {
      this.wrapperClassSetting = "vx-row flex flex-row items-center";
      this.multiselectWrapperClass =
        "vx-col sm:w-2/3 w-full whitespace-no-wrap";
      this.labelClass = "vx-col sm:w-1/3 w-full flex flex-col";
    }
  },
  methods: {
    onTeritoryChange(value) {
      if (this.multiSelect) {
        if (value.length === 0) {
          const allValue = [
            { ID: 0, TerritoryCode: "All", TerritoryName: "All" },
          ];
          this.selectedTerritory = this.$store.commit(
            "operatingUnit/setSelectedTerritories",
            allValue
          );
          this.selectedTerritory = allValue;
          return;
        }

        if (value[0].ID === 0) {
          value.shift();
          this.$store.commit("operatingUnit/setSelectedTerritories", value);
          this.selectedTerritory = value;
        } else if (value.some((ou) => ou.ID === 0)) {
          this.selectedTerritory = [
            { ID: 0, TerritoryCode: "All", TerritoryName: "All" },
          ];
          this.$store.commit(
            "operatingUnit/setSelectedTerritories",
            this.selectedTerritory
          );
        } else {
          this.selectedTerritory = value;
          this.$store.commit(
            "operatingUnit/setSelectedTerritories",
            this.selectedTerritory
          );
        }
      } else {
        this.selectedTerritory = value;
        this.$store.commit(
          "operatingUnit/setSelectedTerritory",
          this.selectedTerritory
        );
      }
    },
    onShow() {
      this.$emit("onShow");
    },
    queryTerritory({ TerritoryCode, TerritoryName }) {
      if (!TerritoryCode || !TerritoryName) {
        return "Select territory";
      } else if (TerritoryCode === "All") {
        return `(${TerritoryCode}), ${TerritoryName}`;
      }
      return `(${TerritoryCode}), ${TerritoryName}`;
    },
  },
  computed: {
    selectedTerritory: {
      get() {
        if (this.multipleTerritory) {
          return this.$store.state.operatingUnit.filter.selectedTerritories;
        } else {
          return this.$store.state.operatingUnit.filter.selectedTerritory;
        }
      },
      set(value) {
        if (this.multipleTerritory) {
          if (value.length === 0) {
            this.$store.commit("operatingUnit/setSelectedTerritories", [
              this.$store.state.operatingUnit.filter.territory[0],
            ]);
            return;
          }
          if (value[0].ID === 0) {
            value.shift();
            this.$store.commit("operatingUnit/setSelectedTerritories", value);
            // this.selectedTerritory = value;
          } else if (value.some((ou) => ou.ID === 0)) {
            // this.selectedTerritory = [
            //   { ID: 0, TerritoryCode: "All", TerritoryName: "All" },
            // ];
            this.$store.commit("operatingUnit/setSelectedTerritories", [
              { ID: 0, TerritoryCode: "All", TerritoryName: "All" },
            ]);
          } else {
            // this.selectedTerritory = value;
            this.$store.commit("operatingUnit/setSelectedTerritories", value);
          }
        } else {
          this.$store.commit("operatingUnit/setSelectedTerritory", value);
        }
      },
    },
  },
};

// onTerritoryChange
</script>
