<template>
  <div class="vx-col md:w-1/1 w-full">
    <vx-card title="Credit Limit Request">
      <!-- Tabs -->
      <vs-tabs v-model="selected.tab">
        <vs-tab label="Draft">
          <div class="con-tab-ejemplo">
            <list-credit-risk
              :selected="selected"
              :option="option"
              :status="1"
              @changeTab="selectedTab"
            ></list-credit-risk>
          </div>
        </vs-tab>
        <vs-tab label="Waiting">
          <div class="con-tab-ejemplo">
            <list-credit-risk
              :selected="selected"
              :option="option"
              :status="2"
              @changeTab="selectedTab"
            ></list-credit-risk>
          </div>
        </vs-tab>
        <vs-tab label="Inquiry">
          <div class="con-tab-ejemplo">
            <list-credit-risk
              :selected="selected"
              :option="option"
              :status="3"
              @changeTab="selectedTab"
            ></list-credit-risk>
          </div>
        </vs-tab>
        <vs-tab label="Approved">
          <div class="con-tab-ejemplo">
            <list-credit-risk
              :selected="selected"
              :option="option"
              :status="4"
              @changeTab="selectedTab"
            ></list-credit-risk>
          </div>
        </vs-tab>
        <vs-tab label="Rejected">
          <div class="con-tab-ejemplo">
            <list-credit-risk
              :selected="selected"
              :option="option"
              :status="5"
              @changeTab="selectedTab"
            ></list-credit-risk>
          </div>
        </vs-tab>
        <vs-tab label="Cancel">
          <div class="con-tab-ejemplo">
            <list-credit-risk
              :selected="selected"
              :option="option"
              :status="6"
              @changeTab="selectedTab"
            ></list-credit-risk>
          </div>
        </vs-tab>
      </vs-tabs>
    </vx-card>
  </div>
</template>

<script>
import list from "./list/list.vue";
import form from "./form/form.vue";

export default {
  components: {
    "list-credit-risk": list,
    "form-credit-risk": form,
  },
  data() {
    return {
      option: {},
      selected: {
        tab: 0,
      },
    };
  },
  methods: {
    selectedTab(value = false) {
      this.selected.tab = value;
    },
  },
};
</script>
